import { USER_LOGOUT, USER_LOGIN, AuthState, AuthActionTypes, USER_POPULATED } from './types';

const initialState: AuthState = {
  user: null,
};

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case USER_LOGOUT: {
      return {
        ...initialState,
      };
    }
    case USER_POPULATED:
    case USER_LOGIN: {
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    }
    default:
      return state;
  }
}
