import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { green } from '@material-ui/core/colors';
import { Subject } from 'rxjs';
import { forgotPassword } from '../../store/auth/actions';
import MSSnackbar from '../../components/MSSnackbar';
import Utils from '../utils';

export const TopicForgotPassword = 'TopicForgotPassword';

export const forgotPasswordSubject = new Subject<{
  topic: string;
  data: any;
}>();

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.mesumas.com/">
        Mesumas
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  success: {
    backgroundColor: green[600],
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  paragraph: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    margin: theme.spacing(0),
  },
}));

export const schema = Yup.object().shape({
  username: Yup.string().required('El nombre de usuario es obligatorio'),
});

const ForgotPassword = () => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const obs = Utils.subscribe(forgotPasswordSubject, TopicForgotPassword, ({ data }) => {
      setOpen(data.open);
      setSubmitting(!data.open);
    });
    return () => {
      obs.unsubscribe();
    };
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <MSSnackbar variant={'success'} message="Se ha enviado un correo a su cuenta de respaldo" open={open} />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <HelpOutlineIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Olvidé mi contraseña
        </Typography>
        <Typography component="p" className={classes.paragraph}>
          ¿Olvidaste tu contraseña? Por favor ingrese su nombre de usuario. Recibirá un link a su cuenta de correo de
          respaldo para crear una nueva contraseña.
        </Typography>
        <Formik
          initialValues={{
            username: '',
          }}
          validationSchema={schema}
          onSubmit={async (values) => {
            setSubmitting(true);
            setOpen(false);
            dispatch(forgotPassword(values.username || ''));
          }}
          render={({ errors, touched, isValid, handleSubmit }) => {
            const usernameValid = !(errors.username && touched.username);
            return (
              <form
                className={classes.form}
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  if (isValid) handleSubmit();
                }}
              >
                <Field
                  name="username"
                  render={(props: any) => {
                    return (
                      <TextField
                        variant="outlined"
                        margin="normal"
                        error={!usernameValid}
                        helperText={!usernameValid && errors.username}
                        required
                        fullWidth
                        id="username"
                        placeholder="mi_usuario"
                        label="Nombre de usuario"
                        autoComplete="email"
                        onChange={(e) => {
                          props.field.setFieldValue('username', e.target.value, true);
                        }}
                        {...props.field}
                      />
                    );
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                  className={classes.submit}
                >
                  {submitting ? <CircularProgress size={30} className={classes.progress} /> : 'Recuperar'}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/login" variant="body2">
                      Volver al Login
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={8}>
                  <Copyright />
                </Box>
              </form>
            );
          }}
        />
      </div>
    </Container>
  );
};

export default ForgotPassword;
