import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import configureStore from './store';
import * as serviceWorker from './serviceWorker';
import App from './App';

const mainTheme = createTheme({
  palette: {
    secondary: {
      main: '#57616E',
    },
    primary: {
      main: '#10b981',
      contrastText: '#FFFFFF',
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.MY_APP = { unsubscribers: [] };

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={configureStore()}>
    <CssBaseline />
    <ThemeProvider theme={mainTheme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
