export interface ErrorState {
  error: Error | null;
  type: AppError | null;
}

export enum AppError {
  RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE',
  USERNAME_PASSWORD_ERROR = 'USERNAME_PASSWORD_ERROR',
}

export interface ResetErrorAction {
  type: keyof typeof AppError;
  payload: Error;
}

export type ErrorActionTypes = ResetErrorAction;
