import { PrinterState, PrinterActionTypes, PRINTER_SELECTED } from './types';

const initialState: PrinterState = {
  name: '',
};

export function printerReducer(state = initialState, action: PrinterActionTypes): PrinterState {
  switch (action.type) {
    case PRINTER_SELECTED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
