export const TRX_ACCUMULATION_CREATE = 'TRX_ACCUMULATION_CREATE';
export const TRX_REDEMPTION_CREATE = 'TRX_REDEMPTION_CREATE';
export const TRX_FETCH = 'TRX_FETCH';

export interface TrxState {
  transactions: Trx[];
}

export interface Voucher {
  id: string;
  qrCode: string;
  createdAt: any;
  place: {
    name: string;
  };
  amount: string;
}

export enum TrxStatus {
  OPENED = 'opened',
  SCANNED = 'scanned',
  ACCEPTED = 'accepted',
  DENIED = 'denied',
  DELETED = 'deleted',
  EXPIRED = 'expired',
}

export enum TrxType {
  ACCUMULATION = 'accumulation',
  REDEMPTION = 'redemption',
}

export interface TrxForm {
  billingId: string;
  amount: string;
  table: string;
}

export interface Trx {
  id?: string;
  type: TrxType;
  status: TrxStatus;
  amount: number;
  billingId: string;
  table: string;
  place: {
    id: string;
    name: string;
    rate?: number;
    logoUrl?: string;
  };
  points?: number;
  createdAt: any;
  updatedAt: any;
  accumulated?: number;
  scannedBy?: {
    id: string;
    name: string;
    category?: string;
    email: string;
    date: any;
  };
  createdBy?: {
    id: string;
    name: string;
    email: string;
    date: any;
  };
  deletedBy?: {
    id: string;
    name: string;
    email: string;
    date: any;
  };
  acceptedBy?: {
    id: string;
    name: string;
    email: string;
    date: any;
  };
  deniedBy?: {
    id: string;
    name: string;
    email: string;
    date: any;
  };
}

export interface AccumulationCreateAction {
  type: typeof TRX_ACCUMULATION_CREATE;
  payload: Trx;
}

export interface RedemptionCreateAction {
  type: typeof TRX_REDEMPTION_CREATE;
  payload: Trx;
}

export interface FetchAction {
  type: typeof TRX_FETCH;
  payload: Trx[];
}

export type TrxActionTypes = AccumulationCreateAction | RedemptionCreateAction | FetchAction;

export interface Place {
  id?: string;
  name: string;
  address: {
    street: string;
    city: string;
    country: string;
    geo: any;
  };
  businessId: string;
  description: string;
  billing: {
    day: number;
    hour: number;
  };
  favs: {
    sum: number;
    total: number;
  };
  images: string[];
  tags: string[];
  contact: {
    phone: string;
    email: string;
  };
}
