export interface PrinterState {
  name: string;
}

export const PRINTER_NOT_FOUND = 'PRINTER_NOT_FOUND';
export const PRINTER_SELECTED = 'PRINTER_SELECTED';

export interface PrinterNotFoundAction {
  type: typeof PRINTER_NOT_FOUND;
}

export interface PrinterSelectedAction {
  type: typeof PRINTER_SELECTED;
  payload: {
    name: string;
  };
}

export type PrinterActionTypes = PrinterNotFoundAction | PrinterSelectedAction;
