import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppError } from './types';
import { AppState } from '..';

type ThunkType = ThunkAction<void, AppState, null, Action<string>>;

export const sendError =
  (type: AppError, error: any): ThunkType =>
  async (dispatch) => {
    dispatch({
      type: type,
      payload: error,
    });
  };

export const clearError = (): ThunkType => async (dispatch) => {
  dispatch({
    type: AppError.RESET_ERROR_MESSAGE,
  });
};
