import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import find from 'lodash.find';
import AsynComponents from './AsyncLoadComponents';
import { setUserToState } from './store/auth/actions';
import './store/firebase';
import { authRef } from './store/firebase';
import { AppState } from './store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      textAlign: 'center',
      margin: theme.spacing(10),
    },
  }),
);

const App = () => {
  const initialising = useAuthState(authRef)[1];
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialising) {
      dispatch(setUserToState());
    }
  }, [dispatch, initialising]);

  if (initialising) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );
  }

  const hasPermission = (pageKey: string, user: any) => {
    if (!user) return false;
    return !!find(user.menus || [], { key: pageKey });
  };

  const PrivateRoute = ({ component, pageKey, ...rest }: any) => {
    const Component = component ? component : () => null;
    const user = useSelector((state: AppState) => state.auth.user);
    const auth = !!authRef.currentUser;
    if (!user) {
      // No set to state
      if (!auth) {
        // No authenticated should be redirected
        return (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        );
      }
      // Else should return null and wait for the state to be updated
      return null;
    }

    return (
      <Route
        {...rest}
        render={({ staticContext, ...rest }) => {
          return auth && hasPermission(pageKey, user) ? (
            <div {...rest}>{Component}</div>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <Router>
      <Switch>
        <PrivateRoute exact pageKey="main_menu" path="/" component={<AsynComponents.Voucher />} />
        <PrivateRoute exact pageKey="profile" path="/profile" component={<AsynComponents.Profile />} />
        <PrivateRoute exact pageKey="billing" path="/billing" component={<AsynComponents.Billing />} />
        {
          <PrivateRoute
            exact
            pageKey="admin/register"
            path="/admin/register"
            component={<AsynComponents.AdminRegister />}
          />
        }
        <Route exact path="/forgot-password" component={AsynComponents.ForgotPassword} />
        <Route path="**" component={AsynComponents.Login} />
      </Switch>
    </Router>
  );
};

export default App;
