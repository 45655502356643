import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import sortBy from 'lodash.sortby';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { USER_LOGOUT, USER_LOGIN, USER_POPULATED, AuthUser, USER_SET_METADATA } from './types';
import { AppState, globalLocalForage } from '..';
import { authRef, usersRef, logRef, metaRef } from '../firebase';
import { AppError } from '../error/types';
import { sendError } from '../error/actions';
import { forgotPasswordSubject, TopicForgotPassword } from '../../pages/ForgotPassword/ForgotPassword';

type ThunkType = ThunkAction<void, AppState, null, Action<string>>;

export const logout = (): ThunkType => async (dispatch) => {
  await authRef.signOut();
  await globalLocalForage.clear();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.MY_APP.unsubscribers.forEach((unsubscribe) => {
    unsubscribe();
  });
  dispatch({
    type: USER_LOGOUT,
  });
};

const getUserInfo = async () => {
  const { currentUser } = authRef;
  const initVal = { email: '', name: '', id: '', metadata: null };
  if (!currentUser) {
    return initVal;
  }
  try {
    const userInfo = await currentUser.getIdTokenResult();
    return {
      id: userInfo.claims.user_id,
      email: userInfo.claims.email,
    };
  } catch (error) {
    console.error('ERROR: ', error);
    return initVal;
  }
};

export const getCurrentUser = async (): Promise<AuthUser | null> => {
  const { email, id } = await getUserInfo();

  if (!id) throw new Error('Invalid user id');

  try {
    const userFromDB = await getDoc(doc(usersRef, id as any));
    let menus = [];
    let places = [];
    let nameDB = '';

    if (userFromDB.exists()) {
      menus = sortBy(userFromDB.data()?.menus || [], ['order']);
      places = userFromDB.data()?.places;
      nameDB = userFromDB.data()?.name;
    }
    return {
      id: id as string,
      email: email as string,
      name: nameDB,
      menus: menus || [],
      places: places || {},
    };
  } catch (error) {
    console.error('ERROR', error);
  }
  return null;
};

export const login =
  (username: string, password: string): ThunkType =>
  async (dispatch) => {
    try {
      await signInWithEmailAndPassword(authRef, username, password);
      const currUser = await getCurrentUser();
      dispatch({
        type: USER_LOGIN,
        payload: currUser,
      });
    } catch (error) {
      dispatch(sendError(AppError.USERNAME_PASSWORD_ERROR, error));
    }

    return {};
  };

export const forgotPassword =
  (username: string): ThunkType =>
  async () => {
    try {
      await sendPasswordResetEmail(authRef, username);
      // await axios.post("auth/forgot-password", {
      //  username: username,
      //});
    } catch (error) {
      console.error('ERROR: ', error);
    } finally {
      forgotPasswordSubject.next({
        topic: TopicForgotPassword,
        data: { open: true },
      });
    }
  };

export const setUserToState = (): ThunkType => async (dispatch) => {
  try {
    const currUser = await getCurrentUser();

    dispatch({
      type: USER_POPULATED,
      payload: currUser,
    });
  } catch (error) {
    logEvent(logRef, 'auth_error', { method: USER_POPULATED });
  }
};

/**
 * setUserMetadata
 */
export const setUserMetadata =
  (value: any): ThunkType =>
  async (dispatch, getState) => {
    try {
      const currUser = getState().auth.user;

      if (!currUser) return {};

      await setDoc(doc(metaRef, currUser.id), { ...value }, { merge: true });

      dispatch({
        type: USER_SET_METADATA,
      });
    } catch (error) {
      logEvent(logRef, 'auth_error', { method: USER_POPULATED });
    }
  };
