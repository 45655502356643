import { PLACE_SELECTED, GlobalState, GlobalActionTypes } from './types';

const initialState: GlobalState = {
  place: null,
};

export function globalReducer(state = initialState, action: GlobalActionTypes): GlobalState {
  switch (action.type) {
    case PLACE_SELECTED: {
      return {
        ...state,
        place: action.payload,
      };
    }
    default:
      return state;
  }
}
