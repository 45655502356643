export interface GlobalState {
  place: Place | null;
}

export interface Place {
  id: string;
  placeId: string;
  name: string;
  logoUrl: string;
}

export const PLACE_SELECTED = 'PLACE_SELECTED';

export interface PlaceSelectedAction {
  type: typeof PLACE_SELECTED;
  payload: Place;
}

export type GlobalActionTypes = PlaceSelectedAction;
