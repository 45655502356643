import { ErrorState, AppError, ErrorActionTypes } from './types';

const initialState: ErrorState = {
  error: null,
  type: null,
};

export const errorReducer = (state = initialState, action: ErrorActionTypes): ErrorState => {
  const { type, payload } = action;

  if (type === AppError.RESET_ERROR_MESSAGE) {
    return initialState;
  } else if (type === AppError.USERNAME_PASSWORD_ERROR) {
    return {
      type: AppError[type],
      error: payload,
    };
  }

  return state;
};
