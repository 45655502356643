import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection } from 'firebase/firestore';

const backofficeConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(backofficeConfig);

export const logRef = getAnalytics(app);

export const authRef = getAuth(app);

export const dbRef = getFirestore();
//enableIndexedDbPersistence(dbRef, { forceOwnership: false });

export const perfRef = getPerformance(app);

export const usersRef = collection(dbRef, 'users');

export const placeRef = collection(dbRef, 'places');

export const trxRef = collection(dbRef, 'transactions');

export const metaRef = collection(dbRef, 'metadata');

authRef.languageCode = 'es';
