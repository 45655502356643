import { TrxState, TrxActionTypes, TRX_FETCH } from './types';

const initialState: TrxState = {
  transactions: [],
};

export function trxReducer(state = initialState, action: TrxActionTypes): TrxState {
  switch (action.type) {
    case TRX_FETCH: {
      return {
        ...state,
        transactions: action.payload,
      };
    }
    default:
      return state;
  }
}
