export interface ProfileState {
  userInfo: UserInfo;
}

export interface UserInfo {
  name: string;
}

export interface UserPassword {
  prevPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const PROFILE_UPDATE_USER_NAME = 'PROFILE_UPDATE_USER_NAME';
export const PROFILE_UPDATE_USER_PASSWORD = 'PROFILE_UPDATE_USER_PASSWORD';
export const PROFILE_ERROR = 'PROFILE_ERROR';

export interface UpdateUserNameAction {
  type: typeof PROFILE_UPDATE_USER_NAME;
  payload: UserInfo;
}

export interface UpdateUserPasswordAction {
  type: typeof PROFILE_UPDATE_USER_PASSWORD;
}

export interface ErrorProfileAction {
  type: typeof PROFILE_ERROR;
}

export type ProfileActionTypes = UpdateUserNameAction | UpdateUserPasswordAction | ErrorProfileAction;
