import {
  ProfileState,
  ProfileActionTypes,
  PROFILE_UPDATE_USER_NAME,
  PROFILE_UPDATE_USER_PASSWORD,
  PROFILE_ERROR,
} from './types';

const initialState: ProfileState = {
  userInfo: {
    name: '',
  },
};

export function profileReducer(state = initialState, action: ProfileActionTypes): ProfileState {
  switch (action.type) {
    case PROFILE_UPDATE_USER_NAME: {
      return {
        ...state,
        userInfo: action.payload,
      };
    }
    case PROFILE_UPDATE_USER_PASSWORD: {
      return state;
    }
    case PROFILE_ERROR: {
      return state;
    }
    default:
      return state;
  }
}
