import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export default class Utils {
  public static subscribe = <T, D>(
    subject: Subject<{ topic: T; data: D }>,
    topic: T,
    subscribe: ({ data: D }: { data: any }) => void,
  ) => {
    return subject.pipe(filter(({ topic: t }) => t === topic)).subscribe(subscribe);
  };
}
