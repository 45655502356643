import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import localforage from 'localforage';
import RSVP from 'rsvp';
import { authReducer } from './auth/reducers';
import { globalReducer } from './global/reducer';
import { trxReducer } from './trx/reducer';
import { profileReducer } from './profile/reducer';
import { errorReducer } from './error/reducer';
import { USER_LOGOUT } from './auth/types';
import { printerReducer } from './printer/reducer';
// eslint-disable-next-line import/extensions
import './sha.js';
//import "./hotjar";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.RSVP = RSVP;

export const globalLocalForage = localforage.createInstance({
  name: 'global',
});

export const printerLocalForage = localforage.createInstance({
  name: 'printer',
});

globalLocalForage.config();
printerLocalForage.config();

export type AppState = ReturnType<typeof rootReducer>;

const appReducer = combineReducers({
  auth: authReducer,
  printer: printerReducer,
  global: globalReducer,
  trx: trxReducer,
  profile: profileReducer,
  error: errorReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(rootReducer, composeWithDevTools(middleWareEnhancer));

  return store;
}
