export interface AuthState {
  user: AuthUser | null;
}

export interface AuthUser {
  id: string;
  name: string;
  email: string;
  places: any[];
  menus: any[];
}

export interface AuthMetadata {
  placeSelected: string;
}

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_POPULATED = 'USER_POPULATED';
export const USER_SET_METADATA = 'USER_SET_METADATA';

export interface LogoutAction {
  type: typeof USER_LOGOUT;
}

export interface LoginAction {
  type: typeof USER_LOGIN;
  payload: AuthUser;
}

export interface PopulateUserAction {
  type: typeof USER_POPULATED;
  payload: AuthUser;
}

export type AuthActionTypes = LogoutAction | LoginAction | PopulateUserAction;
