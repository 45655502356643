import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import LoadingPage from './components/LoadingPage';

const loading = (props: any) => {
  const root = document.getElementById('root');
  if (props.error && root) {
    return ReactDOM.createPortal(null, root);
  } else if (props.pastDelay) {
    if (!root) return null;
    return ReactDOM.createPortal(null, root);
  } else {
    return <LoadingPage />;
  }
};

const defaulConf = {
  loading,
  delay: 500,
};
const AsynComponents = {
  Login: Loadable({
    loader: () => import('./pages/Login/Login'),
    ...defaulConf,
  }),
  Voucher: Loadable({
    loader: () => import('./pages/Voucher/Voucher'),
    ...defaulConf,
  }),
  Profile: Loadable({
    loader: () => import('./pages/Profile/Profile'),
    ...defaulConf,
  }),
  ForgotPassword: Loadable({
    loader: () => import('./pages/ForgotPassword/ForgotPassword'),
    ...defaulConf,
  }),
  Billing: Loadable({
    loader: () => import('./pages/Billing/Billing'),
    ...defaulConf,
  }),
  AdminRegister: Loadable({
    loader: () => import('./pages/AdminRegister/AdminRegister'),
    ...defaulConf,
  }),
};

export default AsynComponents;
